import Api from "./Api";
import Axios from "axios";
// var config = {
//     headers: {'Access-Control-Allow-Origin': '*'}
// };
export default {
  uploadFiles(body) {
    // Axios.post('/api/dashuser/UploadFiles', body, config)
    return Api().post("/api/dashuser/UploadFiles", body);
  },

  getBookingList() {
    return Api().get("api/dashuser/GetBookingList");
  },

  getSplashByID(SplashID) {
    return Api().get("api/Splash/GetSplashByID?SplashID=" + SplashID);
  },

  getUserReports(CinemaID) {
    return Api().get("api/dashuser/GetUserReports?CinemaID=" + CinemaID);
  },

  // New Saif

  getSplashScreen() {
    return Api().post("api/Splash/Get");
  },

  getCinemaByID(payload) {
    return Api().post("/api/dashuser/GetCinemaByID", payload);
  },
  getExpiry() {
    return Api().post("/api/dashuser/GetCinemaExpiryList");
  },
  addCinema(payload) {
    return Api().post("/api/dashuser/AddCinema", payload);
  },
  getCinemaChargesByID(payload) {
    return Api().post("/api/dashuser/GetCinemaChargesByID", payload);
  },
  addCinemaCharges(payload) {
    return Api().post("/api/dashuser/AddCinemaCharges", payload);
  },
  getCinemas(payload) {
    payload.Source="cineco",
    payload.organization_id="3"
    return Api().post("/api/dashuser/GetCinemaList", payload);
  },
  getCinemaClasses(payload) {
    return Api().post("/api/dashuser/getseatclasslist", payload);
  },
  getMinSeats(payload) {
    return Api().post("/api/dashuser/GetMinSeats/", payload);
  },
  addCinemaSchedule(payload) {
    return Api().post("/api/dashuser/AddCinemaSchedule", payload);
  },
  getCinemaScheduleListID(payload) {
    return Api().post("api/dashuser/CinemaScheduleListID", payload);
  },
  getCinemaScheduleList() {
    return Api().post("api/dashuser/CinemaScheduleList/");
  },
  getCouponByID(payload) {
    return Api().post("api/dashuser/GetCouponByID", payload);
  },
  addCoupon(payload) {
    return Api().post("api/dashuser/AddCoupon", payload);
  },
  addMallCoupon(payload) {
    return Api().post("admin/addMallOffers/", payload);
  },
  getCoupons(payload) {
    return Api().post("api/dashuser/GetCouponList", payload);
  },
  getMallCoupons(payload) {
    return Api().post("admin/getMallOffersList/", payload);
  },
  getUserReportsData(payload) {
    return Api().post("api/dashuser/GetUserReports/", payload);
  },

  getAdminFinance(data) {
    return Api().post(`api/dashuser/GetAdminFinance/`, {
      OrgID: data.OrgID,
      CinemaID: data.cinemaId,
      FromDate: data.fromDate,
      ToDate: data.toDate,
      MovieID: data.movieId,
    });
  },
  setTransferID(payload) {
    return Api().post("api/dashuser/SetTransferID", payload);
  },
  addSplashScreen(body) {
    if (body.SplashID != null) {
      body.Flag = 2;
    } else {
      body.SplashID = undefined;
      body.Flag = 1;
    }
    return Api().post("api/splash/addSplash", body);
  },

  /**
   * Back
   */
  getCinemasDDL(payload) {
    (payload.Source = "cineco"), (payload.organization_id = "3");
    return Api().post("api/dashuser/GetddlCinemaList", payload);
  },
  getOrganization() {
    return Api().post("api/dashuser/GetddlOrgList/");
  },

  /**
   * NEW SAIF
   */
  getActiveCinemas(payload) {
    payload.Source = "cineco",
    payload.organization_id= "3"
    return Api().post("admin/getactivecinemas", payload);
  },
  getOrganization() {
    let payload = {
      "Source": "cineco",
      "organization_id": "3"
  }
    return Api().post("admin/getactiveorganizations", payload);
  },
  getDistributorList() {
    return Api().post("admin/getactivedistributors/");
  },
};
